import Certifications from "views/IndexSections/Certifications";
import MetaDecorator from "views/IndexSections/MetoDecorator";
import SmartTools from "views/IndexSections/SmartTools";
import Van from "views/IndexSections/Van";
import Avantages from "views/IndexSections/avantages/Avantages";
import Machine from "views/IndexSections/machine/Machine";
import Partners from "../IndexSections/Partners";
import VideoText from "../IndexSections/VideoText";
import Map from "../IndexSections/map/Map";
import Footer from "../global/Footer";
import Products from "./products/Products";

// <section className="section section-lg" />

function Home() {
	return (
		<>
			<span id="Home" />
			<MetaDecorator title={"Home"} description={"home"} />
			<VideoText />

			<Machine />
			<Avantages />
			<Products />
			<Map />
			<Van />
			<SmartTools />
			<Certifications />
			<Partners />

			<Footer />
		</>
	);
}

export default Home;
