import i18n from "i18next";
import "../../assets/css/mobileAppButtons.css";
import appstroreEng from "../../assets/img/app/appstoreen.svg";
import appStroreFr from "../../assets/img/app/appstorefr.svg";
import googleEn from "../../assets/img/app/googleplayen.png";
import googleFr from "../../assets/img/app/googleplayfr.png";
import URLS from "../../js/Urls";

function MobileAppButtons() {
	return i18n.language === "en" ? (
		<div className="mt-4 ">
			<a href={URLS.GOOGLE_PLAY}>
				<img
					alt="Get it on Google Play"
					src={googleEn}
					className="googleEn"
				/>
			</a>
			<a href={URLS.APPLE_STORE} target="_blank" rel="noreferrer">
				<img
					src={appstroreEng}
					alt="Download on the App Store"
					className="appleEn"
				/>
			</a>
		</div>
	) : (
		<div className="mt-4 ">
			<a href={URLS.GOOGLE_PLAY}>
				<img
					alt="Get it on Google Play"
					src={googleFr}
					className="googleFr"
				/>
			</a>
			<a href={URLS.APPLE_STORE} target="_blank" rel="noreferrer">
				<img
					src={appStroreFr}
					alt="Download on the App Store"
					className="appleFr"
				/>
			</a>
		</div>
	);
}

export default MobileAppButtons;
