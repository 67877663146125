import { useTranslation } from "react-i18next";
import { onMobile } from "views/global/App";
import "../../assets/css/video.css";
import Counter from "./Counter";
import MobileAppButtons from "./MobileAppButtons";
function VideoText() {
	const { t } = useTranslation();

	return (
		<div className="main">
			{/* Utiliser URL valide pour Apple/Safari */}
			<video
				src={"/videos/homeVideo.mp4"}
				playsInline
				autoPlay
				muted
				loop
			/>

			<div className="content overlayVideo1" />
			<div className="content overlayVideo2" />
			<div className="content overlayVideo3" />
			<div className="content gradientWhite " />
			<div className="content  ">
				<Counter />
				<h1
					style={{
						marginTop: onMobile() ? 40 : 80,
						paddingLeft: "2px",
						paddingRight: "2px",
						color: "white",
					}}
					className="text-white"
				>
					{t("slogan")}
				</h1>

				<MobileAppButtons />
			</div>
		</div>
	);
}

export default VideoText;
