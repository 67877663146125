import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import "../../assets/css/partners.css";
import ecocup from "../../assets/img/partner/ecocup.png";
import parte from "../../assets/img/partner/parte.png";
import sadc from "../../assets/img/partner/sadc.png";
import sthenry from "../../assets/img/partner/sthenry.png";
import iconTurism from "../../assets/img/partner/turismo.png";
import URLS from "../../js/Urls";
import { onMobile } from "../global/App.js";
import Carroussel from "./Carroussel/Carroussel";

function Partners() {
	const { t } = useTranslation();
	const partners = [
		{ source: URLS.ECOCUP, image: ecocup },
		{ source: URLS.TOURISME_DURABLE, image: iconTurism },
		{ source: "", image: sthenry },
		{ source: URLS.CCB, image: parte },
		{ source: URLS.SADC, image: sadc },
	];
	const [page, setPage] = useState(0);
	const [partnersPerPage, setpartnersPerPage] = useState(2);
	const adjustLayout = () => {
		if (window.innerWidth < 300) {
			setpartnersPerPage(1);
			setPage(0);
		} else if (window.innerWidth < 550) {
			setpartnersPerPage(2);
			setPage(0);
		} else if (window.innerWidth < 650) {
			setpartnersPerPage(3);
			setPage(0);
		} else if (window.innerWidth < 800) {
			setpartnersPerPage(4);
			setPage(0);
		} else if (window.innerWidth > 800) {
			setpartnersPerPage(5);
			setPage(0);
		}
	};
	useEffect(() => {
		adjustLayout();

		const handleResize = () => {
			adjustLayout();
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			<div className="pb-2">
				<Container fluid>
					<Row className="justify-content-center">
						<Col className="text-center" lg="12">
							<h1>{t("home.partners")}</h1>
						</Col>
					</Row>
					{!onMobile() ? (
						<div className="grid-partner">
							{partners.map((partner, index) => (
								<div key={index + 1} className="grid-child">
									{partner.source ? (
										<a
											target="_blank"
											rel="noreferrer"
											href={partner.source}
										>
											<img
												className="partner-img"
												src={partner.image}
												alt={`Kupa Station ${t(
													"home.partners"
												)}`}
											/>
										</a>
									) : (
										<img
											className="partner-img"
											src={partner.image}
											alt={`Kupa Station ${t(
												"home.partners"
											)}`}
										/>
									)}
								</div>
							))}
						</div>
					) : (
						<Carroussel
							list={partners.map((partner, index) =>
								partner.source ? (
									<a
										target="_blank"
										rel="noreferrer"
										href={partner.source}
									>
										<img
											className="partner-img"
											src={partner.image}
											alt={`Kupa Station ${t(
												"home.partners"
											)}`}
										/>
									</a>
								) : (
									<img
										className="partner-img"
										src={partner.image}
										alt={`Kupa Station ${t(
											"home.partners"
										)}`}
									/>
								)
							)}
							productsPerPage={partnersPerPage}
							page={page}
							setPage={setPage}
						/>
					)}
				</Container>
			</div>
		</>
	);
}

export default Partners;
