// https://youtu.be/WZcxJGmLbSo

import {
	GoogleMap,
	InfoWindow,
	Marker,
	useLoadScript,
} from "@react-google-maps/api";
import { filteredAlphabetic, filteredSearch } from "js/filter";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { machineLocations } from "../../../api/requests";
import "../../../assets/css/map.css";
import bluePin from "../../../assets/img/location/pinbleu.png";
import greenPin from "../../../assets/img/location/pinvert.png";
import userPin from "../../../assets/img/location/userPin.png";

import keys from "../../../assets/json/keys.json";
import CustomSpinner from "../CustomSpinner";
import Toggle from "../Toggle";
import PopoverMachine from "./PopoverMachine";
import PopoverUser from "./PopoverUser";
import mapStyle from "./mapStyle";

const mapContainerStyle = {
	width: "100%",
	height: "500px",
};

const zoom = 6.5;

const pinSize = 35;

const options = {
	styles: mapStyle,
};
function Map() {
	const { t } = useTranslation();

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: keys.GOOGLE_MAPS_API_KEY,
	});

	const [center, setCenter] = useState({
		lat: 46.82102979039321,
		lng: -71.20705170785274,
	});

	const [checkboxPublic, setCheckboxPublic] = useState(true);
	const [checkboxPrivate, setCheckboxPrivate] = useState(true);

	const [numMachines, setNumMachines] = useState(0);
	const [machines, setMachines] = useState(undefined);
	const [userLocation, setUserLocation] = useState(undefined);

	const [selected, setSelected] = useState(null);

	const [search, setSearch] = useState("");

	const handleOnChangePublic = () => {
		setCheckboxPublic(!checkboxPublic);
		setSelected(null);
	};

	const handleOnChangePrivate = () => {
		setCheckboxPrivate(!checkboxPrivate);
		setSelected(null);
	};

	// https://www.movable-type.co.uk/scripts/latlong.html
	function distance(lat1, lon1, lat2, lon2) {
		const R = 6371; // km
		const r = Math.PI / 180;
		const φ1 = lat1 * r; // φ, λ in radians
		const φ2 = lat2 * r;
		const Δφ = (lat2 - lat1) * r;
		const Δλ = (lon2 - lon1) * r;

		const a =
			Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
			Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		const d = R * c; // in km
		return d;
	}

	function listMachines() {
		let filter = filteredAlphabetic(
			filteredSearch(machines, "public_name", search),
			"public_name"
		);

		if (userLocation) {
			filter = filter.sort((a, b) => {
				return (
					distance(
						userLocation.latitude,
						userLocation.longitude,
						a.latitude,
						a.longitude
					) -
					distance(
						userLocation.latitude,
						userLocation.longitude,
						b.latitude,
						b.longitude
					)
				);
			});
		}

		filter = filter.filter(
			(element) =>
				(checkboxPublic && element.public) ||
				(checkboxPrivate && !element.public)
		);

		if (filter.length === 0) return <div>{t("map.search.noresult")}</div>;

		return filter.map((machine, index) => (
			<div key={index} className="vertical-elem">
				{/* <p style={{ display: "inline" }}>{index + 1}.</p> */}
				<a
					style={{
						color: machine.public
							? "var(--kupablue)"
							: "var(--kupagreen)",
					}}
					className="ml-1 "
					target="_blank"
					rel="noreferrer"
					href={`https://maps.google.com/?q=${machine.address}`}
				>
					{machine.public_name.split("|")[0]}
				</a>
				{userLocation && (
					<p
						className="ml-3 textKupa"
						style={{ display: "inline", whiteSpace: "nowrap" }}
					>
						(
						{Math.round(
							distance(
								userLocation.latitude,
								userLocation.longitude,
								machine.latitude,
								machine.longitude
							)
						)}{" "}
						km)
					</p>
				)}
				{machine.count > 1 && (
					<p
						className="ml-1 textKupa"
						style={{ display: "inline", whiteSpace: "nowrap" }}
					>
						({machine.count} stations)
					</p>
				)}
			</div>
		));
	}

	// Count machines et ne pas avoir de double ou plus
	function newList(machines) {
		let count = 1;
		let newMachines = machines;

		for (let i = 0; i < newMachines.length; i++) {
			if (newMachines[i].address) {
				// delete

				for (let j = i + 1; j < newMachines.length; j++) {
					if (newMachines[i].address === newMachines[j].address) {
						count += 1;
						newMachines[j].address = null; // delete
					}
				}
				newMachines[i].count = count;
				count = 1;
			}
		}

		return newMachines.filter((machine) => {
			return machine.address;
		});
	}

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setUserLocation({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				});

				setCenter({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
			});
		}

		async function fetchData() {
			machineLocations()
				.then((response) => {
					setNumMachines(response.length);
					setMachines(newList(response));
				})
				.catch((error) => setMachines(null));
		}
		fetchData();
	}, []);

	if (loadError || machines === null) return null;

	return (
		<Container fluid className="">
			{!isLoaded || machines === undefined ? (
				<CustomSpinner size={6} />
			) : (
				<Row>
					<Col className="" xl="5">
						<div className="textTitle textKupa mt-0 justifyCenter find_us">
							{t("map.find.us")}
						</div>
						<div className="filtersMap">
							<div className="toggleResponsive">
								<span className="textKupa justifyCenter">
									{t("map.public_toggle")}
								</span>
								<Toggle
									color="var(--kupablue)"
									checked={checkboxPublic}
									onChange={handleOnChangePublic}
								/>
							</div>

							<div className="toggleResponsive">
								<span className="textKupa justifyCenter">
									{t("map.private_toggle")}
								</span>
								<Toggle
									color="var(--kupagreen)"
									checked={checkboxPrivate}
									onChange={handleOnChangePrivate}
								/>
							</div>

							<input
								type="text"
								onChange={(e) => setSearch(e.target.value)}
								style={{ maxWidth: "400px", display: "inline" }}
								className="smooth-border form-control"
								placeholder={t("map.search")}
							/>
						</div>
						<div
							style={{ fontSize: 20, marginLeft: "14px" }}
							className="  textKupa mt-2"
						>
							{t("map.installed", { count: numMachines })}
						</div>
						<div className="mt-2">
							<div className="vertical-menu text-left">
								{listMachines()}
							</div>
						</div>
					</Col>

					<Col className="map" xl="7">
						<div
							style={{ borderRadius: "15px", overflow: "hidden" }}
						>
							<GoogleMap
								mapContainerStyle={mapContainerStyle}
								zoom={zoom}
								center={center}
								options={options}
							>
								{userLocation && (
									<Marker
										pinColor="#B9CF50"
										position={{
											lat: userLocation.latitude,
											lng: userLocation.longitude,
										}}
										icon={{
											url: userPin,
											scaledSize:
												new window.google.maps.Size(
													pinSize,
													pinSize
												),
										}}
										onClick={() =>
											setSelected(userLocation)
										}
									/>
								)}

								{machines.map((machine, index) => {
									if (
										(checkboxPublic && machine.public) ||
										(checkboxPrivate && !machine.public)
									) {
										return (
											<Marker
												key={index}
												position={{
													lat: machine.latitude,
													lng: machine.longitude,
												}}
												icon={{
													url: machine.public
														? bluePin
														: greenPin,
													scaledSize:
														new window.google.maps.Size(
															pinSize,
															pinSize
														),
												}}
												onClick={() =>
													setSelected(machine)
												}
											/>
										);
									}
									return null;
								})}

								{selected && (
									<InfoWindow
										position={{
											lat: selected.latitude,
											lng: selected.longitude,
										}}
										onCloseClick={() => setSelected(null)}
									>
										{selected === userLocation ? (
											<PopoverUser />
										) : (
											<PopoverMachine
												machine={selected}
											/>
										)}
									</InfoWindow>
								)}
							</GoogleMap>
						</div>
					</Col>
				</Row>
			)}
		</Container>
	);
}

export default Map;
