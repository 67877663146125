import { useTranslation } from "react-i18next";
import { onMobile } from "views/global/App";
import "../../assets/css/van.css";
import Showroom from "../../assets/img/van/Showroom.png";
import Showroom_mobile from "../../assets/img/van/Showroom_mobile.png";

function Van() {
	const { t } = useTranslation();

	return (
		<div className="mt-5 mb-5">
			<div className="van-div">
				<img
					className={onMobile() ? "van-img_mobile" : "van-img"}
					src={onMobile() ? Showroom_mobile : Showroom}
					alt="Kupa station showroom"
				/>
				{onMobile() ? (
					<div className="van-text">
						<h2
							className=""
							style={{ color: "black", paddingInline: 7 }}
						>
							{t("home.showroom.title")}
						</h2>
						<div
							style={{
								color: "white",
								padding: "5px",
								paddingTop: "15px",
								lineHeight: "normal",
								fontSize: "14px",
							}}
						>
							{t("home.showroom.subtitle")}
						</div>
					</div>
				) : (
					<div className="van-text">
						<h2 className="" style={{ color: "black" }}>
							{t("home.showroom.title")}
						</h2>
						<div style={{ color: "white" }}>
							{t("home.showroom.subtitle")}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Van;
